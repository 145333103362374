import {createRouter, createWebHistory} from 'vue-router'

//路由数组
const routes = [

    {
        //main
        path: "/",

        name: "index",

        component: () => import("./views/Index"),

        children: []

    },
    {
        //main
        path: "/rule",

        name: "rule",

        component: () => import("./views/Rule"),

        children: []

    },
    {
        path: "/404",
        name: "404Error",
        component: () => import("./views/errors/404Error")
    }

]

//路由对象
const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes //上面的路由数组

})


/*
* to 要跳转到哪里
* from 从哪里跳转来
* next 是一个方法，可以传入下一个跳转路由的路径，如果不传参数，代表直接跳转到to这个路由
*/
router.beforeEach((to, from, next) => {

    // console.log(to.matched.length)
    // console.log(from)
    // console.log(next)

    //进行判断，如果to路径没有匹配到现有的任何一个路由
    //作用：当to的路由为空时不跳转，同时当from的路由也为空时，则跳转到404页面
    if (to.matched.length === 0) {

        //这里打印输出
        console.log(to, from, next)

        //这里用三元表达式进行了判断，当from.name也就是跳转来的路由名称不为空，则直接跳转到from所代表的路由，否则跳转到404页面
        from.name ? next({name: from.name}) : next("/404");

    } else {
        //这里打印输出
        console.log(to)

        // eslint-disable-next-line no-empty
        if (to && to.name === "index" || to && to.name === undefined) {

            //cid company_id  企业ID
            //open_id wx openid

            if (to.query.cid == null || to.query.cid == undefined) {
                location.replace("https://redpacket.cdzhengqi.com/404")
            }

            if (to.query.open_id == null || to.query.open_id == undefined) {
                location.replace("https://act.cdzhengqi.com/act/redpacket?cid=" + to.query.cid)
            }
        }

        //如果to的路由名称不为空，则进行跳转
        next();
    }

})

//导出路由对象，在main.js中引用
export default router
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import {Popup, OverLay, Icon,Toast ,Animate} from '@nutui/nutui';
import "@nutui/nutui/dist/style.css";

createApp(App)
    .use(router)
    .use(Popup)
    .use(Toast)
    .use(OverLay)
    .use(Icon)
    .use(Animate)
    .mount('#app')
